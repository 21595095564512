import './index.scss';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { toAbsoluteUrl } from '_metronic';
import Cookie from '../../_metronic/layout/cookie-consent/Cookie';
import '_metronic/_assets/sass/pages/login/login-1.scss';
import { requests as authRequests } from 'redux/auth/reducer';
import { getStatusLogin } from 'redux/auth/selector';
import { actions as i18nActions } from '_metronic/ducks/i18n';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Login(props) {
  const queryParams = useQuery();

  const LATAM_BG = toAbsoluteUrl('/media/bg/bg-3.jpg');
  const EUROPE_BG = toAbsoluteUrl('/media/bg/bg-5.jpg');
  let isLatam = true;
  let backgroundImg = LATAM_BG;

  if (
    [
      'staging.garantiawf.com',
      'localhost',
      'www.myafprojecttool.com',
      'myafprojecttool.com',
    ].includes(window.location.hostname)
  ) {
    isLatam = false;
    backgroundImg = EUROPE_BG;
  }

  console.log(window.location.hostname);

  const { intl, login, hasError, isLoading, setLanguage } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  useEffect(() => {
    const defaultLang = isLatam ? 'ar' : 'gb';
    const lang = queryParams.get('lang') ?? defaultLang;
    if (lang == 'br') {
      setLanguage('br');
    } else if (lang == 'en' || lang == 'gb') {
      setLanguage('gb');
    } else {
      setLanguage(lang.toLowerCase());
    }
  }, [queryParams, setLanguage, isLatam]);

  useEffect(() => {
    if (isLoading) {
      enableLoading();
    } else {
      disableLoading();
    }
  }, [isLoading]);

  const [attempts, setAttempts] = useState(0);
  const [lockoutTime, setLockoutTime] = useState(null);

  const handleLoginAttempt = (values) => {
    if (attempts >= 5) {
      // Si ya ha fallado 5 veces, calculamos el tiempo de desbloqueo
      const currentTime = new Date();
      const unlockTime = new Date(currentTime.getTime() + 15 * 60000); // Bloqueo de 15 minutos
      setLockoutTime(unlockTime); // Establecemos lockoutTime en el estado
      return; // Salimos sin hacer nada más (no permitimos el login)
    }

    // Si la autenticación falla, incrementamos el número de intentos
    login({ body: values });
    setAttempts(attempts + 1);
  };

  useEffect(() => {
    // Reseteamos el número de intentos a 0 después de que se haya desbloqueado
    if (lockoutTime && new Date() >= lockoutTime) {
      setAttempts(0);
      setLockoutTime(null); // Limpiamos el lockoutTime una vez se haya desbloqueado
    }
  }, [lockoutTime]);

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                //backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-3.jpg')})`,
                //backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-5.jpg')})`,
                //backgroundImage: `url(${toAbsoluteUrl('/media/bg/xmas/bg-xmas.jpg',)})`, //xmas remove
                backgroundImage: `url(${backgroundImg})`,
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    width="250"
                    height="44"
                    alt="Logo"
                    // src={toAbsoluteUrl('/media/logos/logo-es-blanco.png')}
                    src={toAbsoluteUrl(
                      intl.formatMessage({ id: 'LOGO-BLANCO' }),
                    )}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">
                    {isLatam && <FormattedMessage id="3M Window Films" />}

                    {!isLatam && (
                      <>
                        3M <span className="architectural">architectural</span>
                        <span className="finishes">finishes</span>
                      </>
                    )}
                  </h3>
                  <h4 className="kt-login__subtitle">
                    {/* <FormattedMessage id="La mejor solución en control solar, confort, seguridad y privacidad para tu automóvil, empresa y hogar" /> 
                      <FormattedMessage id="La mejor solución en control solar, confort, seguridad y privacidad para tu automóvil, empresa y hogar" />*/}
                  </h4>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <div className="kt-login__head">
                {/* <img
                  src={`${toAbsoluteUrl('/media/bg/xmas/trees.png')}`}
                  className="santa"
                  style={
                    //xmas remove
                    {
                      width: '100px',
                      display: 'flex',
                      marginLeft: 'auto',
                    }
                  }
                /> */}
              </div>

              <div className="kt-login__body">
                <div className="kt-login__form">
                  <div className="kt-login__title">
                    <h3>
                      <FormattedMessage id="Ingresa tus datos" />
                    </h3>
                  </div>

                  <Formik
                    initialValues={{
                      username: '',
                      password: '',
                    }}
                    validate={(values) => {
                      const errors = {};

                      if (!values.username) {
                        errors.username = intl.formatMessage({
                          id: 'Debe ingresar un nombre de usuario',
                        });
                      }

                      if (!values.password) {
                        errors.password = intl.formatMessage({
                          id: 'Debe ingresar una contraseña',
                        });
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                      handleLoginAttempt(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form
                        noValidate={true}
                        autoComplete="off"
                        className="kt-form"
                        onSubmit={handleSubmit}
                      >
                        {hasError && attempts < 5 && (
                          <div role="alert" className="alert alert-danger">
                            <div className="alert-text">
                              {intl.formatMessage({
                                id: 'Usuario o contraseña invalidos',
                              })}
                            </div>
                          </div>
                        )}

                        {attempts >= 5 && lockoutTime && (
                          <div
                            role="alert"
                            className="alert alert-danger text-center"
                          >
                            <div className="alert-text">
                              {intl.formatMessage({
                                id:
                                  'Máximo de intentos alcanzado. Reintenta a las:',
                              })}{' '}
                              {lockoutTime.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </div>
                          </div>
                        )}

                        <div className="form-group">
                          <TextField
                            type="text"
                            label="User"
                            margin="normal"
                            className="kt-width-full"
                            name="username"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            helperText={touched.username && errors.username}
                            error={Boolean(touched.username && errors.username)}
                          />
                        </div>

                        <div className="form-group">
                          <TextField
                            type="password"
                            margin="normal"
                            label="Password"
                            className="kt-width-full"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            helperText={touched.password && errors.password}
                            error={Boolean(touched.password && errors.password)}
                          />
                        </div>

                        <div className="kt-login__actions">
                          <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={isLoading}
                            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                              {
                                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                              },
                            )}`}
                            style={loadingButtonStyle}
                          >
                            <FormattedMessage id="Ingresar" />
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <Link className="text-center" to="/reset-password">
                    {' '}
                    <h5>
                      {intl.formatMessage({
                        id: 'Olvide mi contraseña',
                      })}
                    </h5>{' '}
                  </Link>
                  <Link className="text-center" to="/pre-register">
                    {' '}
                    <h5>
                      {intl.formatMessage({
                        id: 'Registrate aquí',
                      })}
                    </h5>{' '}
                  </Link>
                </div>
              </div>
              <Cookie />
              <div className="kt-login__footer">
                {/* <FormattedMessage id="Necesita una cuenta" />{' '}*/}
                <Link
                  to="/auth/forgot-password"
                  className="kt-link kt-login__link-forgot"
                >
                  <FormattedMessage id="Solicitar" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(authRequests.create(payload)),
  setLanguage: (lang) => {
    dispatch(i18nActions.setLanguage(lang));
  },
});

export default injectIntl(connect(getStatusLogin, mapDispatchToProps)(Login));
