import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { requests as userInstallersRequest } from 'redux/userInstallers/reducer';
import { getUserInstallersOptions } from 'redux/userInstallers/selector';
import get from 'lodash/get';
import moment from 'moment';
export const Installer = ({
  values,
  fetchUserInstallersRequest,
  userInstallersOptions,
}) => {
  useEffect(() => {
    fetchUserInstallersRequest();
  }, [fetchUserInstallersRequest]);

  const formatDate = 'DD/MM/YYYY';



  const employees = values.data.employees ?? [];
  return (
    <>
      {console.log(values.data)}
      {console.log(userInstallersOptions)}
      <div className="row">
        {/*<div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Fecha de instalación" />:
            </strong>
            <span>
              {moment(get(values, 'installed', '')).format(formatDate)}
            </span>
          </div>
  </div>*/}

        {employees.map((employe, index) => (
          <div className="col-12 ViewOrEdit" key={index}>
            <div>
              <strong>
                <FormattedMessage id="Instalador" /> {index + 1}:
              </strong>
              {/*<span>{get(employe, 'id', '-')}</span>*/}
              <span>
                {get(
                  userInstallersOptions.find((item) =>
                    userInstallersOptions.find(
                      (item) => item.value.id === employees[index].id,
                    ),
                  ),
                  'label',
                )}
              </span>
            </div>
            {employees.length - 1 !== index && <div className="kt-space-20" />}
          </div>
        ))}
      </div>
    </>
  );
};
export const mapStateToProps = (state) => ({
  userInstallersOptions: getUserInstallersOptions(state),
});

export const mapDispatchToProps = (dispatch) => ({
  fetchUserInstallersRequest: () => dispatch(userInstallersRequest.fetch()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Installer);
//export default Installer;
